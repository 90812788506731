// import React from "react";
// import "./App.css";
// import SearchComponent from "./components/SearchComponent";
// import { BrowserRouter as Router } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <ToastContainer />
//         <header className="App-header">
//           <SearchComponent />
//         </header>
//       </div>
//     </Router>
//   );
// }

// export default App;

// App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchComponent from "./components/SearchComponent";
import QuizPage from "./components/QuizPage";
import ResultsPage from "./components/ResultsPage";
import ConversationBetweenModels from "./components/ConversationBetweenModels"; // Import new component
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0A1929',
    },
    secondary: {
      main: '#ADD8E6',
    },
    background: {
      default: '#ADD8E6',
    },
    error: {
      main: '#ADD8E6',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '10px',
          padding: '10px',
          borderRadius: '4px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '10px',
          '& .MuiInputBase-input': {
            borderRadius: '4px',
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '15px', // Rounded corners
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' // Soft shadow
        }
      }
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    button: {
      textTransform: 'none',
    }
  }
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />  
      <Router>
        <div className="App">
          <ToastContainer />
          <header className="App-header">
            <Routes>
              <Route path="/" element={<SearchComponent />} />
              <Route path="/quiz/:quizId" element={<QuizPage />} />
              <Route path="/quiz/:quizId/results" element={<ResultsPage />} />
              <Route path="/conversation_between_models" element={<ConversationBetweenModels />} />
              {/* Add more routes as needed */}
            </Routes>
          </header>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
