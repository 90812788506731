import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const ResultsPage: React.FC = () => {
    const location = useLocation();
    const params = useParams();

    // Define your types here
    const answers = (location.state as { answers: { [key: number]: boolean } }).answers;
    const quizId = params.quizId as string; // Ensure quizId is a string

    const correctAnswersCount = Object.values(answers).filter(isCorrect => isCorrect).length;
    const wrongAnswersCount = Object.keys(answers).length - correctAnswersCount;

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4">Results for Quiz: {quizId}</Typography>
            <Typography variant="subtitle1">Correct Answers: {correctAnswersCount}</Typography>
            <Typography variant="subtitle1">Wrong Answers: {wrongAnswersCount}</Typography>
        </Box>
    );
};

export default ResultsPage;
