import React, { useState, useEffect, useRef, useMemo, RefObject } from 'react';
import ReactMarkdown from 'react-markdown';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles'; // Import useTheme to access the current theme


interface Message {
    sender: string;
    content: string;
    id: string;
}

interface TypingState {
    model1: boolean;
    model2: boolean;
}
const ConversationBetweenModels: React.FC = () => {
    const theme = useTheme(); // Access the theme
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [model1, setModel1] = useState<string>('GPT-4');
    const [model2, setModel2] = useState<string>('Gemini Pro');
    const messageEndRef1 = useRef<null | HTMLDivElement>(null);
    const messageEndRef2 = useRef<null | HTMLDivElement>(null);
    const [isTyping, setIsTyping] = useState<TypingState>({ model1: false, model2: false });

    useEffect(() => {
        // Ensures that the viewport is scrolled to the bottom after any changes to the messages
        if (messages.length > 0) {
            scrollToBottom(messageEndRef1);
            scrollToBottom(messageEndRef2);
        }
    }, [messages]); // Dependency only on messages as refs don't usually trigger updates

    // const processedMessages = useMemo(() => {
    //     return messages.map(msg => ({
    //         ...msg,
    //         processedContent: processMessage(msg.content)
    //     }));
    // }, [messages]);

    const scrollToBottom = (ref: RefObject<HTMLDivElement>) => {
        if (ref.current) {
            const { scrollHeight, scrollTop, clientHeight } = ref.current;
            // Check if the user is near the bottom (within 100 pixels)
            if (scrollHeight - scrollTop - clientHeight < 100) {
                ref.current.scrollTop = scrollHeight;
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const handleModelChange = (event: SelectChangeEvent, modelSetter: React.Dispatch<React.SetStateAction<string>>) => {
        modelSetter(event.target.value as string);
    };
    const simulateTypingEffect = async (message: Message, ref: RefObject<HTMLDivElement>) => {
        const senderKey = message.sender as keyof typeof isTyping;
        if (isTyping[senderKey]) {
            // console.log(`Typing effect is blocked for ${message.sender} because it is already active.`);
            return;  // Block if already typing
        }

        setIsTyping(prev => ({ ...prev, [senderKey]: true }));
        // console.log(`Typing started for ${message.sender}`);

        const uniqueId = Date.now().toString() + Math.random().toString(16).slice(2);
        setMessages(prev => [...prev, { ...message, content: '', id: uniqueId }]);

        return new Promise(resolve => {
            let typedContent = '';
            let i = 0;
            const intervalId = setInterval(() => {
                if (i < message.content.length) {
                    typedContent += message.content.charAt(i);
                    setMessages(prev => prev.map(msg => msg.id === uniqueId ? { ...msg, content: typedContent } : msg));
                    scrollToBottom(ref);
                    i++;
                } else {
                    clearInterval(intervalId);
                    setIsTyping(prev => ({ ...prev, [senderKey]: false }));
                    // console.log(`Typing finished for ${message.sender}`);
                    resolve(true);
                }
            }, 25);
        });
    };

    const fetchModelResponse = async (input: string, endpoint: string, sender: string) => {
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ input })
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data from the server. Status: ${response.status}`);
            }

            const data = await response.json();
            if (!data || (data.answer === undefined && data.question === undefined)) {
                throw new Error("No response from model");
            }
            return { sender, content: data.answer || data.question || "No content received" };
        } catch (error) {
            // console.error("Error fetching model response:", error);
            throw new Error("An unexpected error occurred while fetching data.");
        }
    };


    const handleStartConversation = async () => {
        if (!inputText.trim()) {
            setError('Please enter a topic to start the conversation.');
            return;
        }
        setError('');
        setMessages([]);  // Clear messages at the start of each new conversation
        try {
            let currentInput = inputText;
            let currentSender = 'model1';
            // let currentEndpoint = 'http://0.0.0.0:8080/api/v1/discuss_model_gpt4_host_start';
            let currentEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/discuss_model_gpt4_host_start'; 
            for (let i = 0; i < 10; i++) {  // Adjust the number of turns as needed
                const response = await fetchModelResponse(currentInput, currentEndpoint, currentSender);
                const nextTargetRef = currentSender === 'model1' ? messageEndRef1 : messageEndRef2;
                await simulateTypingEffect({ sender: currentSender, content: response.content, id: Date.now().toString() + Math.random().toString(16).slice(2) }, nextTargetRef);

                currentInput = response.content;
                // Ensure next turn waits until the current typing effect is completely finished
                currentSender = (currentSender === 'model1') ? 'model2' : 'model1';
                // currentEndpoint = (currentEndpoint === 'http://0.0.0.0:8080/api/v1/discuss_model_gpt4_host_start') ? 'http://0.0.0.0:8080/api/v1/discuss_model_gemini_pro_answer' : 'http://0.0.0.0:8080/api/v1/discuss_model_gpt4_host_start';
                currentEndpoint = (currentEndpoint === 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/discuss_model_gpt4_host_start') ? 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/discuss_model_gemini_pro_answer' : 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/discuss_model_gpt4_host_start';
            }
        } catch (err) {
            // console.error(err);
            setError(err instanceof Error ? err.message : "An unexpected error occurred.");
        }
    };

    // function processMessage(content: string): JSX.Element[] {
    //     const regex = /(Question:|Analysis:)/;
    //     const parts = content.split(regex);
    //     console.log(parts);  // Log to see how the message is being split
    
    //     return parts.map((part, index) => {
    //         if (part === "Question:") {
    //             return (
    //                 <div key={`question-${index}`} style={{ fontWeight: 'bold' }}>
    //                     {part + (parts[index + 1] || '')}
    //                 </div>
    //             );
    //         } else if (part === "Analysis:") {
    //             return (
    //                 <div key={`analysis-${index}`} style={{ fontStyle: 'italic' }}>
    //                     {part + (parts[index + 1] || '')}
    //                 </div>
    //             );
    //         }
    //         return null;
    //     }).filter(element => element !== null) as JSX.Element[];
    // }


    return (
        <Box className="conversation-layout" sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: '100vh',
            padding: 4,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.background.default
        }}>
            <Box sx={{ width: '90%', display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
                <FormControl variant="outlined" sx={{ width: '200px' }}>
                    <InputLabel id="model1-select-label">Host</InputLabel>
                    <Select
                        labelId="model1-select-label"
                        id="model1-select"
                        value={model1}
                        label="Host"
                        onChange={(e) => handleModelChange(e, setModel1)}
                    >
                        <MenuItem value="GPT-4">GPT-4</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ width: '200px' }}>
                    <InputLabel id="model2-select-label">Guest</InputLabel>
                    <Select
                        labelId="model2-select-label"
                        id="model2-select"
                        value={model2}
                        label="Guest"
                        onChange={(e) => handleModelChange(e, setModel2)}
                    >
                        <MenuItem value="Gemini Pro">Gemini Pro</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="message-container" sx={{ display: 'flex', width: '90%', justifyContent: 'space-between', marginBottom: 4, minHeight: '400px' }}>
            <Box sx={{ flex: 1, marginRight: 2, border: 1, borderColor: 'grey.400', padding: 3, minHeight: '60vh', overflow: 'auto', textAlign: 'left' }} ref={messageEndRef1}>
                <Typography variant="h6">{model1} (Host) Questions and Analysis</Typography>
                {messages.filter(msg => msg.sender === 'model1').map((msg, index) => (
                        <React.Fragment key={index}>
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                            <hr style={{ margin: '10px 0' }} /> {/* Horizontal rule for visual separation */}
                        </React.Fragment>
                    ))}
            </Box>
                <Box sx={{ flex: 1, marginLeft: 2, border: 1, borderColor: 'grey.400', padding: 3, minHeight: '60vh', overflow: 'auto', textAlign: 'left' }} ref={messageEndRef2}>
                    <Typography variant="h6">{model2} (Guest) Answers</Typography>
                    {messages.filter(msg => msg.sender === 'model2').map((msg, index) => (
                        <React.Fragment key={index}>
                            <ReactMarkdown>{msg.content}</ReactMarkdown>
                            <hr style={{ margin: '10px 0' }} /> {/* Horizontal rule for visual separation */}
                        </React.Fragment>
                    ))}
                </Box>
            </Box>

            <TextField
                fullWidth
                label="Type your topic of discussion here"
                variant="outlined"
                value={inputText}
                onChange={handleInputChange}
                sx={{ width: '90%', marginBottom: 2, backgroundColor: 'white', borderColor: 'black' }}
            />
            <Button variant="contained" color="primary" onClick={handleStartConversation}>
                Start the Conversation
            </Button>
            {error && <Box color="red">{error}</Box>}
        </Box>
    );
};

export default ConversationBetweenModels;
