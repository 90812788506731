import React, { useEffect, useState, useRef } from 'react';
import { Button, Typography, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism'; // You can choose other styles
import '../App.css';

interface QuestionProps {
    questionNumber: number;
    questionText: string;
    options: string[];
    correctAnswer: string;
    onAnswer: (isCorrect: boolean) => void;
    showResultButton?: boolean; // Optional prop
}

const Question: React.FC<QuestionProps> = ({ questionNumber, questionText, options, correctAnswer, onAnswer, showResultButton = false // Default to false if not provided
}) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [revealAnswer, setRevealAnswer] = useState(false);
    const previousQuestionText = useRef<string>(questionText);

    useEffect(() => {
        if (questionText !== previousQuestionText.current) {
            setSelectedOption(null);
            setRevealAnswer(false);
        }
        previousQuestionText.current = questionText;
    }, [questionText, options, correctAnswer]);

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        const isCorrect = selectedValue === correctAnswer;
        onAnswer(isCorrect);
    };

    return (
      <Box sx={{
        mb: 2, 
        textAlign: 'left', 
        border: '1px solid #ddd', // Add a border
        borderRadius: '8px', // Optional: add rounded corners
        padding: '16px', // Add some padding inside the box
        backgroundColor: '#f9f9f9', // Optional: light background color
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Optional: slight shadow for depth
    }}>            <Typography variant="subtitle1">
                {questionNumber}. 
                {questionText.includes('```') ? (
                    <SyntaxHighlighter language="javascript" style={coy}>
                        {questionText.replace(/```/g, '')}
                    </SyntaxHighlighter>
                ) : (
                    questionText
                )}
            </Typography>
            <RadioGroup 
                value={selectedOption}
                onChange={handleAnswerChange}
            >
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio />}
                        label={option}
                    />
                ))}
            </RadioGroup>

            {showResultButton && (
                <Button variant="outlined" onClick={() => setRevealAnswer((prev) => !prev)}>
                    {revealAnswer ? 'Hide Answer' : 'Show Answer'}
                </Button>
            )}
            {revealAnswer && (
                <Typography variant="body2" sx={{ color: selectedOption === correctAnswer ? 'green' : 'red' }}>
                    Correct Answer: {correctAnswer}
                </Typography>
            )}
        </Box>
    );
};

export default Question;
