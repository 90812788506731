// List.tsx
import React, { useEffect, useState } from 'react';
import { TextField, List as MuiList, ListItem } from '@mui/material';

interface ListProps {
  onSelectTopic: (topic: string) => void;
}

const List: React.FC<ListProps> = ({ onSelectTopic }) => {
    const [topics, setTopics] = useState<string[]>([]);
    const [filteredTopics, setFilteredTopics] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        fetch('https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/get-quiz-topics')
            .then(response => response.json())
            .then(data => {
                setTopics(data.topics);
                setFilteredTopics(data.topics);
            })
            .catch(error => console.error('Error fetching topics:', error));
    }, []);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
        setFilteredTopics(topics.filter(topic => topic.toLowerCase().includes(searchTerm.toLowerCase())));
    };

    return (
        <div>
            <TextField
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Recently popped quizzes..."
                fullWidth
                className="centered-textfield"         
            />
            <div className="list-scroll-container">
                <MuiList>
                    {filteredTopics.map((topic, index) => (
                        <ListItem button key={index} onClick={() => onSelectTopic(topic)}>
                            {topic}
                        </ListItem>
                    ))}
                </MuiList>
            </div>
        </div>
    );
};

export default List;
