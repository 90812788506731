// QuizPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import Question from './Question';

interface QuizQuestion {
    question: string;
    options: { [key: string]: string };
    answer: string;
}

const QuizPage: React.FC = () => {
    const { quizId } = useParams<{ quizId: string }>();
    const navigate = useNavigate();
    const [quizQuestions, setQuizQuestions] = useState<QuizQuestion[]>([]);
    const [answers, setAnswers] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        if (quizId) {
            fetch(`https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/get-questions-by-topic?topic=${encodeURIComponent(quizId)}`)
                .then(response => response.json())
                .then(data => {
                    const quizData = data[quizId];
                    if (Array.isArray(quizData)) {
                        setQuizQuestions(quizData);
                    }
                })
                .catch(error => console.error('Error fetching quiz:', error));
        }
    }, [quizId]);

    const handleAnswer = (questionIndex: number, isCorrect: boolean) => {
        setAnswers(prev => ({ ...prev, [questionIndex]: isCorrect }));
    };

    const showResults = () => {
        navigate(`/quiz/${quizId}/results`, { state: { answers } });
    };

    return (
        <Container maxWidth="xl">
            {/* Increased maxWidth to 'xl' for larger container */}
            <Box sx={{ my: 4, mx: 2 }}> {/* Added margin for spacing */}
                <Typography variant="h4" sx={{
                    textAlign: 'center',
                    fontSize: {
                        xs: '1.25rem', // smaller screens
                        sm: '2.125rem' // defaults to h4 size for larger screens
                    },
                    marginBottom: '5px',
                }}>
                    Quiz: {quizId}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ maxHeight: '70vh', overflowY: 'auto', padding: 2, border: '1px solid #ddd', borderRadius: '4px', marginBottom: '10px' }}>
                            {quizQuestions.map((question, index) => (
                                <Question
                                    key={index}
                                    questionNumber={index + 1}
                                    questionText={question.question}
                                    options={Object.values(question.options)}
                                    correctAnswer={question.answer}
                                    onAnswer={(isCorrect) => handleAnswer(index, isCorrect)}
                                    showResultButton={false}
                                />
                            ))}
                        </Box>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button variant="contained" onClick={showResults} sx={{ width: 'auto', padding: '10px 30px' }}>
                                Show Results
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: 4 }}>
                            <Typography variant="body2" fontStyle="italic" >
                                These questions are generated by AI. Please cross-verify the answers.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default QuizPage;
