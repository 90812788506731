import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, CircularProgress, Typography, Divider, FormControl, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import Question from "./Question";
import List from './List';

import '../App.css';
import 'react-toastify/dist/ReactToastify.css';

const SearchComponent: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [numQuestions, setNumQuestions] = useState<number>(5);
  const [selectedModel, setSelectedModel] = useState<string>('mistral'); // Default to GPT-4
  const [topics, setTopics] = useState([]);
  const isMobile = window.innerWidth <= 768;
  const [isListVisible, setIsListVisible] = useState(!isMobile); // List is visible by default
  const [answers, setAnswers] = useState<{ [key: number]: boolean }>({});

  const navigate = useNavigate();
  const handleAnswer = (questionIndex: number, isCorrect: boolean) => {
    setAnswers({ ...answers, [questionIndex]: isCorrect });
};
  // Dummy function to navigate to a specific quiz page
  const goToDummyQuiz = () => {
    const dummyQuizId = 'Advanced C++'; // Replace with a valid quiz ID
    navigate(`/quiz/${dummyQuizId}`);
  };
  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    // Fetch topics from the backend
    try {
      //const response = await fetch('http://0.0.0.0:8080/api/v1/get-quiz-topics');
      const response = await fetch('https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/get-quiz-topics');
      const data = await response.json();
      setTopics(data.topics);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleTopicSelect = async (topicName: string) => {
    setLoading(true);
    setError(null);

    try {
      // Fetch questions by topic
      const response = await fetch(`https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/get-questions-by-topic?topic=${encodeURIComponent(topicName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      // Extract questions from the response
      const dummyQuizId = topicName; // Replace with a valid quiz ID
      navigate(`/quiz/${dummyQuizId}`);

      // Check if the device is mobile and hide the list
      if (window.innerWidth <= 768) {
        setIsListVisible(false);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError('Failed to load questions.');
    } finally {
      setLoading(false);
    }
  };




  const handleSearchClick = async () => {
    if (!searchTerm.trim()) {
      setError('Please enter a topic to pop the quiz.');
      return;
    }
    setLoading(true);
    setError(null);
    setSearchResults([]);
    let apiEndpoint = '';
    if (selectedModel === 'gpt3.5') {
      //apiEndpoint = 'http://0.0.0.0:8080/api/v1/chat_gpt35'; // Replace with your GPT-3.5 endpoint
      apiEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/chat_gpt35'; // Replace with your GPT-3.5 endpoint
    } else if (selectedModel === 'gpt4') {
      //apiEndpoint = 'http://0.0.0.0:8080/api/v1/chat_gpt4'; // Replace with your GPT-4 endpoint
      apiEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/chat_gpt4'; // Replace with your GPT-4 endpoint
    } else if (selectedModel === 'gemini') {
      //apiEndpoint = 'http://0.0.0.0:8080/api/v1/chat_gemini_pro'; // Replace with your Gemini endpoint
      apiEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/chat_gemini_pro'; // Replace with your Gemini endpoint
    } else if (selectedModel === 'mistral') {
      //apiEndpoint = 'http://0.0.0.0:8080/api/v1/chat_mistral'; // Replace with your Gemini endpoint
      apiEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/chat_mistral'; // Replace with your Gemini endpoint    
    } else if (selectedModel === 'claude3') {
      //apiEndpoint = 'http://0.0.0.0:8080/api/v1/chat_claude3'; // Replace with your Gemini endpoint
      apiEndpoint = 'https://quizpopzbackend-6zywro5sga-el.a.run.app/api/v1/chat_claude3'; // Replace with your Gemini endpoint    
    }

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: searchTerm, num_questions: numQuestions }),
      });
  
      const data = await response.json();
      if (data.error && data.error.code === 'insufficient_quota') {
        setError('You exceeded your current quota. Please check your plan and billing details.');
      } else {
        setSearchResults(data.response);
      }
    } catch (error) {
      console.error(error);
      setError("Could not fetch questions. Please try again later or try a different search term.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-container">
      <div className="left-panel" style={{ display: isListVisible ? 'block' : 'none' }}>
        <List onSelectTopic={handleTopicSelect} />
      </div>
      <button onClick={toggleListVisibility} className="toggle-list-arrow">
        <span className="material-icons">
          {isListVisible ? 'chevron_left' : 'chevron_right'}
        </span>
      </button>
      {/* <Button 
        variant="contained" 
        onClick={goToDummyQuiz} 
        className="test-quiz-button"
      >
        Test Quiz Page
      </Button> */}
      <div className="main-content">
        <Box className="centered-flex-box">
          <Box className="logo-search-box">
            <img src={process.env.PUBLIC_URL + 'logo194.png'} alt="logo" className="logo" />
            <TextField variant="outlined" value={searchTerm} onChange={handleSearchChange} placeholder="Enter topic..." className="search-box" onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchClick();
              }
            }} />
          </Box>

          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '10px' }}>
            {/* Select AI Model */}
            <FormControl style={{ minWidth: '200px', marginRight: '20px' }}>
              <Select
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
                displayEmpty
                style={{ marginTop: '8px' }}
              >
                <MenuItem value="" disabled>Select AI Model</MenuItem>
                <MenuItem value="gpt3.5">GPT-3.5</MenuItem>
                <MenuItem value="gpt4">GPT-4</MenuItem>
                <MenuItem value="gemini">Gemini Pro</MenuItem>
                <MenuItem value="mistral">Mistral AI</MenuItem>
                <MenuItem value="claude3">Claude 3</MenuItem>
              </Select>
            </FormControl>

            {/* Select Number of Questions */}
            <FormControl style={{ minWidth: '200px' }}>
              <Select
                value={numQuestions}
                onChange={(e) => setNumQuestions(Number(e.target.value))}
                displayEmpty
                style={{ marginTop: '8px' }}
              >
                <MenuItem value="" disabled>Select Number of Questions</MenuItem>
                {[1, 3, 5, 10].map((num) => (
                  <MenuItem key={num} value={num}>{num}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Button variant="contained" onClick={handleSearchClick} disabled={loading} className="quiz-button">
            Pop Quiz
          </Button>

          {loading && <CircularProgress sx={{ mb: 2, color: '#FFC107' }} />}
          {error && <Typography color="#D32F2F">{error}</Typography>}
          <Divider sx={{ my: 2, backgroundColor: '#BDBDBD' }} />

          <Box className="questions-display">
            {searchResults === null ? (
              <Typography variant="body2" className="no-questions-message">
                Questions are displayed here.
              </Typography>
            ) : Array.isArray(searchResults) && searchResults.map((data, index) => {
              // Assuming you have a Question component
              const { question: questionText, options, answer: correctAnswer } = data;
              const optionsArray = Object.values(options) as string[];
              const correctAnswerText = correctAnswer;

              return (
                <Question
                  key={`${questionText}-${index}`}
                  questionNumber={index + 1}
                  questionText={questionText}
                  options={optionsArray}
                  correctAnswer={correctAnswerText}
                  onAnswer={(isCorrect) => handleAnswer(index, isCorrect)}
                  showResultButton={true}                  
                  />
              );
            })}
          </Box>
          <div className="footer-message">
            <Typography variant="body2" fontStyle="italic">
              These questions are generated by AI. Please cross-verify the answers.
            </Typography>
          </div>
        </Box>
      </div>

    </div>
  );
};

export default SearchComponent;
